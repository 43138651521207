<template>
  <div class="update-info">
    <el-card>
      <CommonForm
        label-position="right"
        :label-width="labelWidth"
        :formDataGroups="formItemGroups"
        :modifyData="modifyData"
        :createDataType="createDataType"
        confirmTxt="Update"
        :apifunc="apifunc"
      />
    </el-card>
  </div>
</template>

<script>
import CommonForm from '@/components/CommonForm'
import {
  getPatientInfo,
  updatePatientInfo,
  getDestinationList,
  getBedList,
  getUserList,
  getDiagnosisList,
  updatePatientStatus,
  updateTcuInfo,
  getTcuInfo
} from '@/services'
import loadingJson from '@/utils/loading.json'
import jsonData from './jsonData'
export default {
  components: {
    CommonForm
  },
  data() {
    return {
      formType: 'patientProfile',
      formItemGroups: [],
      modifyData: {},
      labelWidth: '180px',
      id: '',
      apifunc: '',
      createDataType: 'patient',
      tcuId: ''
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.formType = this.$route.query.formType || 'patientProfile'
    this.tcuId = this.$route.query.tid || ''
    this.formItemGroups = JSON.parse(JSON.stringify(jsonData[this.formType + 'FormItems']))
    if (this.id) {
      this.initPatientInfo(this.id)
      if (this.formType == 'patientProfile' || this.formType == 'referralAdmission') {
        this.apifunc = (form) => updatePatientInfo(this.id, form)
      } else if (this.formType == 'billing') {
        this.apifunc = (form) => updatePatientStatus(this.id, 'update_billing', form)
      } else if (this.formType == 'tcu'){
        this.createDataType = 'tcu'
        this.apifunc = (form) => updateTcuInfo(this.tcuId, this.id, form)
      }
    }
  },
  methods: {
    async initPatientInfo (id) {
      this.loading = this.$loading(loadingJson)
      try {
        const res = await getPatientInfo(id, false)
        if (res.data.patient) {
          const { patient } = res.data
          if (this.formType == 'tcu') {
            this.patientPartInfo = {
              name: patient.name,
              ic: patient.ic,
              patient_id: patient.id
            }
          } else {
            if (patient.from_institution) {
              patient.from_institution_id = patient.from_institution.id
              patient.from_institution_name = patient.from_institution.name
            }
            if (patient.to_institution) {
              patient.to_institution_id = patient.to_institution.id
              patient.to_institution_name = patient.to_institution.name
            }
            if (patient.doctor) {
              patient.doctor_id = patient.doctor.id
            }
            if (patient.planned_destination) {
              patient.planned_destination_id = patient.planned_destination.id
            }
            if (patient.actual_destination) {
              patient.actual_destination_id = patient.actual_destination.id
            }
            patient.per_day_rate = patient.per_day_rate || '25.00'
            this.modifyData = patient
          }
        }
      } catch (error) {
        
      }
      this.initSelectList()
    },
    async initSelectList () {
      if (this.formType == 'referralAdmission') {
        try {
          const res = await getDestinationList({}, false)
          if (res.data.destinations) {
            this.formItemGroups[1].formDataItems[1].options = res.data.destinations
            this.formItemGroups[1].formDataItems[3].options = res.data.destinations
          }
        } catch (error) {}
        try {
          const res1 = await getBedList({}, false)
          if (res1.data.beds) {
            this.formItemGroups[0].formDataItems[4].options = res1.data.beds
          }
        } catch (error) {}
        try {
          const res2 = await getUserList({role_eq: 'doctor'}, false)
          if (res2.data.users) {
            this.formItemGroups[0].formDataItems[6].options = res2.data.users
          }
        } catch (error) {}
      } else if (this.formType == 'billing') {
        try {
          const res = await getDiagnosisList({}, false)
          if (res.data.diagnoses) {
            this.formItemGroups[0].formDataItems[2].options = res.data.diagnoses
          }
        } catch (error) {}
      } else if (this.formType == 'tcu' && this.tcuId) {
        try {
          const res = await getTcuInfo(this.tcuId, {}, false)
          if (res.data.tcu) {
            this.modifyData = Object.assign((this.patientPartInfo || {}), res.data.tcu)
          }
        } catch (error) {}
      }
     
      if (this.loading) this.loading.close()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>