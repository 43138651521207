import {
  FLIC_GENDER_LIST,
  FLIC_RESIDENCY_LIST,
  FLIC_RAF_LIST,
  FLIC_DISCHARGE_STATUS_LIST,
  FLIC_MIN_REVIEW_TIPS
} from '@/utils/constant'
export default {
  patientProfileFormItems: [
    {
      title: 'Patient Profile',
      name: 0,
      formDataItems: [
        {
          span: 24,
          formtype: 'input',
          prop: 'name',
          label: 'Name',
          placeholder: 'Please enter the Name',
          object: {
            style: 'width: 260px;',
            disabled: true
          }
        },
        {
          span: 24,
          formtype: 'input',
          prop: 'ic',
          label: 'Patient IC',
          placeholder: 'Please enter the Patient IC',
          object: {
            style: 'width: 260px;',
            disabled: true
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'gender',
          label: 'Gender',
          placeholder: 'Please select the Gender',
          options: FLIC_GENDER_LIST,
          object: {
            style: 'width: 260px;',
            key: 'id',
            rules: [
              { required: true, message: 'Please select the Gender', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'date',
          prop: 'birthday',
          label: 'Date of Birth',
          options: [],
          placeholder: 'Please select the Date of Birth',
          object: {
            style: 'width: 260px;',
            rules: [
              { required: true, message: 'Please select the Date of Birth', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'residency',
          label: 'Residency',
          options: FLIC_RESIDENCY_LIST,
          placeholder: 'Please select the Residency',
          object: {
            style: 'width: 260px;',
            rules: [
              { required: true, message: 'Please select the Residency', trigger: 'blur' }
            ]
          }
        },
        {
          span: 9,
          formtype: 'input',
          type: 'number',
          prop: 'phone',
          label: 'Phone Number',
          placeholder: 'Please enter the Phone Number',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 24,
          formtype: 'input',
          prop: 'email',
          label: 'Email',
          placeholder: 'Please enter the Email',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'address',
          label: 'Address',
          placeholder: 'Please enter the Address',
          object: {
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_name',
          label: 'MSP Name',
          placeholder: 'Please enter the MSP Name',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_relation',
          label: 'MSP Relation to Patient',
          placeholder: 'Please enter the MSP Relation to Patient',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_phone',
          label: 'MSP Phone',
          placeholder: 'Please enter the MSP Phone',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'msp_email',
          label: 'MSP Email',
          placeholder: 'Please enter the MSP Email',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 24,
          formtype: 'switch',
          prop: 'mdro',
          label: 'MDRO',
          placeholder: '',
          value: true,
          object: {}
        },
        {
          span: 14,
          formtype: 'input',
          type: 'textarea',
          prop: 'mdro_remarks',
          label: 'MDRO Remarks',
          placeholder: 'Please enter the MDRO Remarks',
          object: {
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Patient Remarks',
          placeholder: 'Please enter the Patient Remarks',
          object: {
          }
        }
      ]
    }
  ],
  referralAdmissionFormItems: [
    {
      title: 'Admission Details',
      name: 0,
      formDataItems: [
        {
          span: 24,
          formtype: 'input',
          prop: 'name',
          label: 'Name',
          placeholder: 'Please enter the Name',
          object: {
            style: 'width: 250px;',
            disabled: true
          }
        },
        {
          span: 24,
          formtype: 'input',
          prop: 'ic',
          label: 'Patient IC',
          placeholder: 'Please enter the Patient IC',
          object: {
            style: 'width: 250px;',
            disabled: true
          }
        },
        {
          span: 9,
          formtype: 'date',
          prop: 'planned_admission_date',
          label: 'Planned Admission Date',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 9,
          formtype: 'date',
          prop: 'actual_admission_date',
          label: 'Actual Admission Date',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'bed_id',
          label: 'Bed Assigned',
          placeholder: 'Please select ...',
          options: [],
          object: {
            style: 'width: 250px;',
            key: 'id'
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'raf',
          label: 'RAF',
          placeholder: 'Please select ...',
          options: FLIC_RAF_LIST,
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'doctor_id',
          label: 'Clerking Doctor',
          placeholder: 'Please select ...',
          options: [],
          object: {
            style: 'width: 250px;',
            key: 'id'
          }
        },
        {
          span: 9,
          formtype: 'inputnumber',
          prop: 'min_review',
          label: 'Min Review Frequency',
          placeholder: '',
          object: {
            style: 'width: 250px;',
            tips: FLIC_MIN_REVIEW_TIPS
          }
        }
      ]
    },
    {
      title: 'Discharge Details',
      name: 1,
      formDataItems: [
        {
          span: 9,
          formtype: 'date',
          prop: 'planned_discharge_date',
          label: 'Planned Discharge Date',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'planned_destination_id',
          label: 'Planned Discharge Destination',
          placeholder: 'Please select ...',
          options: [],
          object: {
            style: 'width: 250px;',
            key: 'id'
          }
        },
        {
          span: 9,
          formtype: 'date',
          prop: 'actual_discharge_date',
          label: 'Actual Discharge Date',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'actual_destination_id',
          label: 'Actual Discharge Destination',
          placeholder: 'Please select ...',
          options: [],
          object: {
            style: 'width: 250px;',
            key: 'id'
          }
        },
        {
          span: 9,
          formtype: 'select',
          prop: 'discharge_status',
          label: 'Discharge Status',
          placeholder: 'Please select ...',
          options: FLIC_DISCHARGE_STATUS_LIST,
          object: {
            style: 'width: 250px;'
          }
        }
      ]
    },
    {
      title: 'Referral Details',
      name: 2,
      formDataItems: [
        {
          span: 9,
          formtype: 'input',
          prop: 'from_institution_name',
          label: 'Referring Institution',
          placeholder: 'Please enter ...',
          object: {
            style: 'width: 250px;',
            disabled: true
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'to_institution_name',
          label: 'Destination Institution',
          placeholder: 'Please enter ...',
          object: {
            style: 'width: 250px;',
            disabled: true
          }
        },
        {
          span: 9,
          formtype: 'input',
          prop: 'from_institution_ward_bed',
          label: 'Ward/Bed in Referring Institution',
          placeholder: 'Please enter ...',
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 9,
          formtype: 'inputnumber',
          prop: 'from_institution_los',
          label: 'LOS in Referring Institution',
          placeholder: '',
          object: {
            style: 'width: 250px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'referral_details',
          label: 'Referral Details',
          placeholder: 'Please enter the Referral Details',
          object: {
            style: 'width: 540px;',
            tips: '*Please provide details on MDRO, msw, nok, function, feeding, nursing care, etc.'
          }
        }
      ]
    }
  ],
  tcuFormItems: [
    {
      title: 'TCU Management',
      name: 0,
      formDataItems: [
        {
          span: 24,
          formtype: 'input',
          prop: 'name',
          label: 'Name',
          placeholder: 'Please enter the Name',
          object: {
            style: 'width: 250px;',
            disabled: true
          }
        },
        {
          span: 24,
          formtype: 'input',
          prop: 'ic',
          label: 'Patient IC',
          placeholder: 'Please enter the Patient IC',
          object: {
            style: 'width: 250px;',
            disabled: true
          }
        },
        {
          span: 24,
          formtype: 'datetime',
          prop: 'planned_at',
          label: 'Date/Time',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 280px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'address',
          label: 'Address',
          placeholder: 'Please enter the Address',
          object: {
            style: 'width: 540px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Doctor Remarks',
          placeholder: 'Please enter the Doctor Remarks',
          object: {
            style: 'width: 540px;'
          }
        }
      ]
    },
    {
      title: 'For Admin’s Use',
      name: 1,
      formDataItems: [
        {
          span: 9,
          formtype: 'switch',
          prop: 'has_accompany',
          label: 'HCA Accompany',
          placeholder: '',
          value: false,
          object: {}
        },,
        {
          span: 9,
          formtype: 'switch',
          prop: 'transport_arranged',
          label: 'Transport Arranged',
          placeholder: '',
          value: false,
          object: {}
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'transport_remarks',
          label: 'Admin Remarks',
          placeholder: 'Please enter the Doctor Admin Remarks',
          object: {
            style: 'width: 540px;'
          }
        }
      ]
    }
  ],
  billingFormItems: [
    {
      title: 'Billing',
      name: 0,
      formDataItems: [
        {
          span: 24,
          formtype: 'input',
          prop: 'name',
          label: 'Name',
          placeholder: 'Please enter the Name',
          object: {
            style: 'width: 260px;',
            disabled: true
          }
        },
        {
          span: 24,
          formtype: 'input',
          prop: 'ic',
          label: 'Patient IC',
          placeholder: 'Please enter the Patient IC',
          object: {
            style: 'width: 260px;',
            disabled: true
          }
        },
        {
          span: 24,
          formtype: 'select',
          prop: 'diagnosis_id',
          label: 'Claim Diagnosis',
          options: [],
          placeholder: 'Please select the Claim Diagnosis',
          object: {
            style: 'width: 260px;',
            key: 'id'
          }
        },
        {
          span: 24,
          formtype: 'switch',
          prop: 'billed',
          label: 'Billed',
          placeholder: '',
          value: false,
          object: {}
        },
        {
          span: 24,
          formtype: 'switch',
          prop: 'paid',
          label: 'Paid',
          placeholder: '',
          value: false,
          object: {}
        },
        {
          span: 24,
          formtype: 'input',
          prop: 'per_day_rate',
          label: 'Per Day Rate',
          value: '25.00',
          placeholder: 'Please enter the Per Day Rate',
          object: {
            style: 'width: 260px;'
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'billing_remarks',
          label: 'Billing Remarks',
          placeholder: 'Please enter the Billing Remarks',
          object: {
            style: 'width: 540px;'
          }
        }
      ]
    }
  ]
}